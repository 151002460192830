$(function(){
    var modal_offline = $('#modal-offline').modal({backdrop: 'static', keyboard: false, show: false});
    window.addEventListener("offline", function () {
        modal_offline.modal('show');
    });
    window.addEventListener("online", function () {
        modal_offline.modal('hide');
    });

    var modal_logout = $('#modal-logout');
    if (modal_logout.length) {
        modal_logout
            .modal({backdrop: 'static', keyboard: false, show: false})
            .on('shown', function () {
                $(document).on('keydown', function (e) { if ((e.which || e.keyCode) == 116) e.preventDefault(); });
                $.get(window.location.protocol + '//' + window.location.hostname + '/logout');
            })
            .on('hidden', function (e) {
                $(e.target).find('input[type="password"]').val('');
                $(document).off('keydown');
            })
            .on('submit', 'form#modal-logout-form', function (e) {
                var modal = $(e.delegateTarget),
                    form  = $(e.target),
                    data  = form.find('input').serialize();

                $.post(form.prop('action'), data)
                    .done(function () {
                        modal.modal('hide');
                    })
                    .fail(function (xhr) {
                        toastr.error(xhr.responseJSON['message']);
                    });

                return false;
            });

        setTimeout(function(){
            $.getJSON(window.location.protocol + '//' + window.location.hostname + '/api/default/logged', function (logged) {
                modal_logout.modal(logged ? 'hide' : 'show');
            });
        }, modal_logout.data('timeout'));
    }

    $(document).ajaxError(function( event, jqxhr, settings, thrownError) {
        if(jqxhr.status === 403) {
            if (window.Sentry !== undefined) {
                $.getJSON(window.location.protocol + '//' + window.location.hostname + '/api/default/logged', {
                    lotacao: window.Simus.lotacao
                }, function (logged) {
                    if (logged) {
                        Sentry.captureMessage('Acesso Negado! \n' + settings.url, Sentry.Severity.Warning);
                    }
                });
            }
        }
    });

    if (window.toastr) {
        window.toastr.subscribe(function(obj) {
            if (obj.state != "visible" || !window.localStorage) {
                return;
            }

            var toastrMessages = JSON.parse(window.localStorage.getItem('toastr-messages')) || [];

            if(!Array.isArray(toastrMessages)) {
                window.localStorage.setItem('toastr-messages', '[]');
                throw Error('LocalStorage do toastr em formato incorreto');
            }

            var startTime = obj.startTime;
            var day = startTime.getDay();
            var month = startTime.getMonth() + 1;
            var formatedDate = (day <= 9 ? '0' + day : day) + '/' + (month <= 9 ? '0' + month : month) + '/' + startTime.getFullYear() + ' ' + startTime.getHours() + ':' + startTime.getMinutes();

            var toastrMessage = {
                type: obj.map.type,
                title: obj.map.title,
                message: obj.map.message,
                data: formatedDate,
                lotacao: window.Simus.lotacao
            };

            toastrMessages = toastrMessages.slice(0, 19);
            toastrMessages.unshift(toastrMessage);
            window.localStorage.setItem('toastr-messages', JSON.stringify(toastrMessages));
        });
    }

    $.fn.popover.defaults.html = true;
    $.fn.popover.defaults.trigger = 'hover';
    $.fn.popover.defaults.container = 'body';

    $('body')
        .popover({selector: '[rel=popover]'})
        .on('dragstart', 'img', function(e){
            // Prevent to move images
            e.preventDefault();
            return false;
        })
        .on('click', 'form [type="submit"]', function () {
            // Prevent multiple submits
            var btnOri = $(this),
                btnNew = btnOri.clone();

            btnNew.prop('disabled', true);
            btnNew.prop('type', 'button');

            btnNew.html('<span class="fa fa-spinner fa-spin fa-lg"></span> Enviando');
            btnNew.val('Enviando');

            btnOri.hide();
            btnOri.after(btnNew);

            setTimeout( function () {
                btnNew.remove();
                btnOri.show();
            }, 3000);
        })
        .on('keyup', function(e) {
            // Close fake modals
            if(e.which == 27) {
                var btn = $('div.modal-editing form div.form-actions a.btn.cancel',this);
                if(btn.length) {
                    window.location.assign(btn.attr('href'));
                    e.preventDefault();
                    return false;
                }
            }
        })
        .on('click', '.panel-collapse > .panel-heading', function(e){
            var target = $(e.target);
            if (!target.hasClass('panel-collapse-prevent') && target.parentsUntil(e.currentTarget, '.panel-collapse-prevent').length === 0) {
                $(this).siblings('.panel-body').slideToggle();
            }
        })
        .on('click', '.toogle-barra-lateral', function(){
            var barra = $('.barra-lateral');
            if (barra.hasClass('barra-ativa')) {
                barra.animate({width: "0px"}, 200);
                barra.html('');
                setTimeout(function(){ $('.barra-lateral').hide() }, 200)
            } else {
                $('#toast-container').remove();

                barra.show().animate({width: "250px"}, 200);

                $.get("/inicio/barraLateral?theme=v3", function( barraView ) {
                    barra.html(barraView);
                    $('.barra-lateral .todos-chamados').animate({width: "250px"}, 200);
                });
            }
            barra.toggleClass('barra-ativa');
        })
        .on('input', '#lotacao_search_input', function() {
            $('#lotacao_search_style').html(!this.value ? '' : '.searchable:not([data-index*="' + this.value.toLowerCase() + '"]) { display: none; }');
        })
        .on('click', '#aside-notificacoes-btn', function() {
            $.get("/api/int/escala/notificacoes", function( notificacoes ) {
                if(notificacoes.length){

                    var toastrMessages = notificacoes;
                    var alertasPane = $('#notificacoespane').empty();

                    if(Array.isArray(toastrMessages)) {
                        toastrMessages.forEach(function(toast) {
                            var toastElement = $('<a href="/cidadao/profissional/perfil/escala?usuario='+toast.id_usuario+'" rel="tooltip" title="Clique para ver a escala">'+
                                    '<div class="toast toast-success">'+
                                    '<div class="toast-title">Nova publicação de escala</div>'+
                                    '<div class="toast-message">' + toast.mensagem + '</div>'+
                                    '<span class="pull-right toast-data">' + toast.datahora + '</span>'+
                                    '</div>'+
                                    '</a>'),
                                toastTitle = $(''),
                                toastMessage = $(''),
                                toastData = $('');

                            toastElement.append(toastTitle, toastMessage, toastData);
                            alertasPane.append(toastElement);
                        });
                    }
                }
            });
        })
        .on('click', '#aside-alertas-btn', function() {
            var toastrMessages = JSON.parse(window.localStorage.getItem('toastr-messages'));
            var alertasPane = $($(this).attr('href')).find('.pane-content').empty();

            if(Array.isArray(toastrMessages)) {
                toastrMessages.forEach(function(toast) {
                    if(toast.lotacao !== window.Simus.lotacao) {
                        return;
                    }
                    var toastElement = $('<div class="toast toast-' + toast.type + '">'),
                        toastTitle = $('<div class="toast-title">' + (toast.title || '') + '</div>'),
                        toastMessage = $('<div class="toast-message">' + toast.message + '</div>'),
                        toastData = $('<span class="pull-right toast-data">' + toast.data + '</span>');

                    toastElement.append(toastTitle, toastMessage, toastData);
                    alertasPane.append(toastElement);
                });
            }
        })
        .on('click', 'form .submit-popover-ajax', function(e) {
            e.preventDefault();
            e.stopPropagation();

            var elem = $(this);

            if (elem.hasClass('disabled') || elem.is(':disabled')) {
                return false;
            }

            var form     = elem.parents('form'),
                url      = elem.attr('formaction') || form.attr('action'),
                method   = form.attr('method') || 'get',
                gridview = elem.data('gridview') || '.grid-view',
                inputs   = form.find('input,select,textarea'),
                redirect = elem.data('redirect'),
                data     = {};

            for (var index = 0; index < inputs.length; index++) {
                var input = inputs[index];

                if ((input.required && !input.value.trim()) || input.checkValidity() === false) {
                    toastr.error('Preencha todos os campos obrigatórios.');
                    return false;
                }

                // Excluir radio e checkbox não selecionados
                if (input.type !== 'checkbox' || (input.type === 'checkbox' && input.checked === true)) {
                    data[input.name] = $(input).val();
                }
            }

            elem.addClass('disabled');

            if (redirect) {
                window.location.assign(url + '?' + $.param(data));
                return false;
            }

            $[method](url, data)
                .done(function (response) {
                    if (response) {
                        toastr.success(response);
                    } else {
                        toastr.success('Requisição bem sucedida!');
                    }

                    if ($(gridview).length > 0) {
                        $(gridview).yiiGridView('update');
                    }

                    $('.tooltip,.popover').remove();
                })
                .fail(function (xhr) {
                    toastr.error(((xhr.responseJSON || {}).message) || xhr.responseText || 'Requisição falhou!');
                })
                .always(function () {
                    elem.removeClass('disabled');
                });

            return false;
        })
        .on('click', '.btn-popover', function(e) { // @see app.js no v4
            e.stopPropagation();
            var btn = $(this);

            if (btn.hasClass('disabled') || btn.is(':disabled')) {
                return false;
            }

            btn.popover('toggle');

            $('[rel="tooltip"]').tooltip('hide');
            $('.btn-popover,.btn-popover-ajax').not(this).popover('hide');
            return false;
        })
        .on('click', '.btn-popover-ajax', function(e) { // @see app.js no v4
            e.stopPropagation();
            var btn = $(this),
                url = btn.data('url'),
                forceReload = !!btn.data('reload'),
                useSpinner = btn.data('spinner');

            if (typeof useSpinner === "undefined") {
                useSpinner = true;
            } else {
                useSpinner = !!useSpinner;
            }

            if (btn.hasClass('disabled') || btn.is(':disabled')) {
                return false;
            }

            if (typeof url === 'undefined') {
                alert('Atributo "data-url" não definido!');
                return false;
            }

            if (btn.data('popover-ajax-ok')) {
                btn.popover("toggle");
                if (forceReload) {
                    btn.data('popover-ajax-ok', false);
                }
            } else {
                if (useSpinner) {
                    var txt = btn.html();
                    btn.html('<i class="fa fa-spin fa-spinner"></i>');
                }

                $.getJSON(url)
                    .done(function (data) {
                        btn.popover('destroy');
                        btn.data('popover-ajax-ok', true);
                        btn.popover({placement: data.placement, title: data.title, content: data.content, trigger: 'manual'});
                    })
                    .fail(function (xhr) {
                        var content = ((xhr.responseJSON || {}).message) || xhr.responseText || 'Requisição falhou!';
                        btn.popover('destroy');
                        btn.popover({title: 'Erro', content: content, trigger: 'manual'});
                    })
                    .always(function () {
                        if (useSpinner) {
                            btn.html(txt);
                        }
                        btn.popover("toggle");
                    });
            }

            $('[rel="tooltip"]').tooltip('hide');
            $('.btn-popover,.btn-popover-ajax').not(this).popover('hide');
            $('.btn-popover-ajax[data-reload]').not(this).data('popover-ajax-ok', false);
            return false;
        })
        .on('change', '.input-data-inicio,.input-data-fim', function () {
            // Manipulação Min e Max em campos de Data
            var changed = $(this),
                parent = changed.parent(),
                data_ini = parent.find('.input-data-inicio'),
                data_fim = parent.find('.input-data-fim');

            if (changed.is(data_ini)) {
                data_fim.attr('min', data_ini.val());
            }

            if (changed.is(data_fim)) {
                data_ini.attr('max', data_fim.val());
            }
        })
        .find('.badge[data-badge-route]').each(function (index, elem) {
        var badge = $(elem),
            url = badge.data('badge-route');

        badge.html('<i class="fa fa-spinner fa-spin" style="font-size: 7px;"></i>');

        $.getJSON(url)
            .done(function(response){
                badge.html(response.total).addClass(response.class);
            })
            .fail(function(xhr) {
                badge.html('&hybull;');
                toastr.error(((xhr.responseJSON || {}).message) || xhr.responseText || 'Requisição falhou!');
            });
    });

    $('form.form-ajax').on('submit', function(e) {
        e.preventDefault();

        var form  = $(this),
            msg   = form.data('ajaxMsg') || 'Enviando',
            modal = $('<div class="modal fade" data-backdrop="static"><div class="modal-body"><h3 style="text-align:center;"><i class="fa-spin fa fa-spinner fa-3x"></i><br>'+msg+'</h3></div></div>'),
            time  = setTimeout(function(){ modal.modal('show'); }, 1000);

        $.ajax({url: form.prop('action'), type: form.prop('method'), data: form.serialize()})
            .done(function(data, status, xhr) {
                clearTimeout(time);
                document.open();
                document.write(data);
                document.close();
                window.history.pushState(null, null, xhr.getResponseHeader("X-Simus-Location"));
            })
            .fail(function(xhr) {
                clearTimeout(time);
                modal.modal('hide');
                if(xhr.responseText) toastr.error(xhr.responseText);
            });

        return false;
    });

    $('#filter-form').on('change', 'input, select, textarea', function () {
        $('#filter-form').trigger('submit');
    });

    // Ir para a tab ao recarregar uma pagina
    $('.nav-tabs a[href="'+window.location.hash+'"]').tab('show');
    window.onpopstate = function(){
        $('.nav-tabs a[href="'+window.location.hash+'"]').tab('show');
    };
    $('.nav-tabs a').on('shown', function (e) {
        if(window.location.hash != e.target.hash) {
            if(history.pushState) {
                history.pushState(null, null, e.target.hash);
            } else {
                window.location.hash = e.target.hash; //Polyfill for old browsers
            }
        }
    });
});

// http://jsfromhell.com/string/is-cpf [rev. #1] Carlos R. L. Rodrigues
String.prototype.isCPF = function () {
    var c = this;
    if ((c = c.replace(/[^\d]/g, "").split("")).length != 11) return false;
    if (new RegExp("^" + c[0] + "{11}$").test(c.join(""))) return false;
    for (var s = 10, n = 0, i = 0; s >= 2; n += c[i++] * s--);
    if (c[9] != (((n %= 11) < 2) ? 0 : 11 - n)) return false;
    for (var s = 11, n = 0, i = 0; s >= 2; n += c[i++] * s--);
    if (c[10] != (((n %= 11) < 2) ? 0 : 11 - n)) return false;
    return true;
};